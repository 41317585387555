import "antd/dist/antd.less";
import "nprogress/nprogress.css";
import "../styles.less";

import { ApolloProvider } from "@apollo/react-hooks";
import { Redirect } from "@app/components";
import { useSharedQuery } from "@app/graphql";
import { pageview, withApollo } from "@app/lib";
import { notification } from "antd";
import moment from "antd/node_modules/moment";
import { useRouter } from "next/router";
import NProgress from "nprogress";
import * as React from "react";

NProgress.configure({
  showSpinner: false,
});

function Effects({ children }: any) {
  const query = useSharedQuery();
  const { data } = query;
  const router = useRouter();

  React.useEffect(() => {
    if (query.data?.currentUser) {
      const {
        id,
        email,
        isAdmin,
        isVerified,
        accountType,
        name,
        registerPricingOption,
        subscriptionEndDate,
        stripeCustomerId,
      } = query.data.currentUser;
      // @ts-ignore window.analytics undefined below
      window.analytics.identify(id, {
        email: email.nodes.find((e) => e.isVerified)?.email || email.nodes[0].email,
        isAdmin,
        isVerified,
        accountType,
        name,
        registerPricingOption,
        subscriptionEndDate,
        stripeCustomerId,
        username: undefined,
        organizationMemberships: undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.data?.currentUser?.id]);

  if (data?.currentUser?.isAdmin) {
    return children;
  } else if (
    router.pathname === "/rhymequiz/[slug]" &&
    (!data?.currentUser?.isVerified || moment(new Date()).isAfter(moment(data?.currentUser?.subscriptionEndDate)))
  ) {
    if (!data?.currentUser?.isVerified) {
      // Redirect back to verify if the user is unverified once they visit a rhymequiz
      return <Redirect href="/verify" />;
    } else {
      // Redirect back to dashboard if the their subscription is past due once they visit a rhymequiz
      return <Redirect href="/dashboard" />;
    }
  } else {
    return children;
  }
}

function MyApp(props: any) {
  const { Component, pageProps, apollo } = props;
  const router = useRouter();

  React.useEffect(() => {
    const handleRouteChange = (url: string) => {
      pageview(url);
      // @ts-ignore window.analytics undefined below
      window.analytics.page(url);
      NProgress.done();
    };

    if (typeof window !== "undefined") {
      router.events.on("routeChangeStart", () => {
        NProgress.start();
      });

      router.events.on("routeChangeComplete", handleRouteChange);
      router.events.on("routeChangeError", (err: Error | string) => {
        NProgress.done();
        if (err["cancelled"]) {
          // No worries; you deliberately cancelled it
        } else {
          notification.open({
            message: "Page load failed",
            description: `This is very embarassing! Please reload the page. Further error details: ${
              typeof err === "string" ? err : err.message
            }`,
            duration: 0,
          });
        }
      });
    }

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloProvider client={apollo}>
      <Effects>
        <Component {...pageProps} key={router.asPath} />
      </Effects>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }: any) => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default withApollo(MyApp);
